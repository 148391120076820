<template>


  <div class="row">

<div class="col-md-12"> 
    <h2 class="info-text">Select the plan/devices that best fits your needs</h2>
    </div>

    <div class="col-md-12">
       <div class="row" >

 <div class="col-md-3">

<card style="width: 15rem;">
    <h4 class="card-title">Professional</h4>
   <p class="card-text"> Enterprize solution with multiples energy points and users. Devices to be add later.</p>
        <base-button 
        @click.native="selected(9,'Enterprize')" 
        slot="footer" round type="primary" class="btn-just-icon">
           <div v-if="plan===9"><i class="tim-icons icon-check-2"></i> </div>  Get started
          </base-button>
</card>
    </div>


     <!-- <div class="col-md-3">

<card style="width: 15rem;">
    <h4 class="card-title">Advanved</h4>
   <p class="card-text">Some quick example text to build on the card title and make up the bulk of the card's content.</p>
  <base-button slot="footer" round type="warning" class="btn-just-icon">
            Get started
          </base-button>
</card>
    </div> -->

    <div class="col-md-3">

<card style="width: 15rem;">
    <h4 class="card-title">M3</h4>
   <p class="card-text">3 phases with 3 alarms.</p>
 <p class="card-text">$10 per month.</p>
   
  <base-button 
  @click.native="selected(2,'M3')" 
  slot="footer" round type="danger" class="btn-just-icon">
        <div v-if="plan===2"><i class="tim-icons icon-check-2"></i> </div>     Add+
          </base-button>
        
</card>
    </div>

    <div class="col-md-3">

<card style="width: 15rem;"
>
    <h4 class="card-title">M1</h4>
   <p class="card-text">2 phases with 1 alarm.</p>
   <p class="card-text">$5 per month.</p>
  <base-button 
  @click.native="selected(1,'M1')" 
  slot="footer" round type="success" class="btn-just-icon">
          <div v-if="plan===1"><i class="tim-icons icon-check-2"></i> </div> Add+
          </base-button>
        
</card>
    </div>

    <div class="col-md-3">

<card style="width: 15rem;">
    <h4 class="card-title">S1</h4>
   <p class="card-text">2 controls 16A</p>
   <p class="card-text">Alarm connected</p>
  <base-button 
  @click.native="selected(3,'S1')" 
  slot="footer" round type="warning" class="btn-just-icon">
          <div v-if="plan===1"><i class="tim-icons icon-check-2"></i> </div> Add+
          </base-button>
        
</card>
    </div>











      </div>

      <div v-if="devices.length>0" align="center"  justify="center">
        <h3> List of Machines selected</h3>
      </div>
     <div class="row">
      <div class="col-lg-3 col-md-6" v-for="(device,index) in devices" :key="device.name">
     
   

        
        <h3>   {{device.name}} 
          <base-button
                @click.native="delte(device.name,index)"                 
                   
    
                    size="sm"
                    
                  >
                    <i class="tim-icons icon-simple-remove"></i>
                  </base-button>
                </h3> 
       
    </div>
  </div>
      </div>

    
    <!-- <h5 class="info-text">Who are you? (Fileinput)</h5> -->



    <!-- <div class="row justify-content-center"> -->
       
      <div class="col-md-12">
    <user-card> </user-card>
     </div>
  </div>
</template>
<script>

import UserCard from './../../Pages/UserProfile/UserCard.vue';
 import swal from 'sweetalert2';
import {Card} from 'src/components'
import firebase from '../../../Firebase'
export default {
  components: {
     UserCard,
     Card,
  },
  data() {
    return {
      plan:0,
      card:false,
      devices:[]
    };
  },
  created(){
      const currentUser = firebase.auth().currentUser
  

  const ref = firebase.firestore().collection('users').doc(currentUser.uid);
    ref.onSnapshot((doc) => {
       if (doc.exists) {
      
        if(doc.data().card.last4){
        console.log("card")
       this.card =true;
        }else{
          console.log("nocard")
           this.card =false;
        }
        
       
      } 
    });

    

  
  },
  methods: {
    delte(name,index){
console.log(name);
this.devices.splice(index, 1);
    },
    selected(plan,name){

      this.plan = plan
      console.log(plan)

      if(plan===9){

        // let pears = this.devices.includes("Enterprize");
        // console.log(pears);
        // if(!this.devices.indexOf('Enterprize') != -1){

        //   this.devices.push({name:name});
        // }
      }else{
        this.devices.push({name:name});
      }
      
    },
   async validate() {

   //console.log("validade")
      if(this.plan===0){
        console.log(this.$user.card.last4)
        swal.fire({
           title: `Please select your plan!`,
           buttonsStyling: false,
           customClass: {
             confirmButton: 'btn btn-success btn-fill'
           }
         })
        // return null;
      }else{


     
     if(!this.card){

          console.log("validade")
          swal.fire({
           title: `Please add your card! You won't be charge now.`,
           buttonsStyling: false,
           customClass: {
             confirmButton: 'btn btn-success btn-fill'
           }
         })
        }else{

let info = {
plan:this.plan,
devices:this.devices

}

          
this.$emit('on-validated3', info);
      return Promise.resolve(true);
        }
 

      }


      
     
    }
  }
};
</script>
<style></style>
