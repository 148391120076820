<template>
  <div class="content">
    <div class="header text-center"><h3 class="title">Timeline</h3></div>
    <div class="row">
      <div class="col-md-12">
        <time-line :key="componentKey" >    
          <time-line-item v-for="item in statsCards" :key="item.key"
         :inverted=item.inv
            :badge-type=item.color
            :badge-icon=item.icon
          >
            <span slot="header" :class="color(item.color)">{{item.name}}</span
            > 
            <temlate slot="content">
              <p v-if="item.save>0">
               I saved ${{formatPrice(item.save)}} in energy
              </p>
              <p>
              {{ item.text}}
                </p>
              <hr />
            </temlate>

            <h6 slot="footer">
                    {{item.like}}
               <base-button
                     @click.native="handleLike(item.key,item.uid)"
                    class="like btn-link"
                    :type="item.liked"
                    size="sm"
                    icon
                  >
                    <i class="tim-icons icon-heart-2"></i>
                  </base-button>
 {{item.comm}}
                   <base-button
                    
                    class="like btn-link"
                    type="info"
                    size="sm"
                    icon
                  >

                  <router-link class="btn-link"  :to="{ name: 'Comments', params: {msg:item.key} }" ><i class="tim-icons icon-chat-33"></i></router-link>
                    
                  </base-button>
 
                  <i class="ti-time">{{ timeConverter(item.timestamp)}}</i> 
            </h6>
            
          </time-line-item>          
        </time-line>
      </div>
    </div>
  </div>
</template>
<script>
import { TimeLine, TimeLineItem } from 'src/components';
import firebase from '../../Firebase';
import moment from 'moment';

export default {
  components: {
    TimeLine,
    TimeLineItem
  },
  data() {
    return {
      uid:"",
      componentKey: 0,
     statsCards: [
      
     ],
     liked: []
    }
    },
   created() {

      const currentUser = firebase.auth().currentUser
  this.uid = currentUser.uid;


 firebase.database().ref('post')
    .orderByChild('timestamp').on("value",  notes => {
      this.statsCards= [];
     notes.forEach(  note => {
this.liked.includes(note.ref.key)
var liked=this.liked.includes(note.ref.key)?"success":"info";

//let user = await firebase.database().ref('users').child(note.child("uid").val()).once("value");
//user.child("firstName").val()+" "+user.child("lastName").val()
this.statsCards.push({

          key:note.ref.key,
          name:note.child("name").val(),
          uid:note.child("uid").val(),
          inv: note.child("inv").val(),
          text: note.child("text").val(),
          save: note.child("save").val(),
           like: note.child("like").val(),
            liked: liked,
            comm:note.child("comm").val(),
          timestamp:note.child("timestamp").val(),
          icon:note.child("icon").val(),
          color:note.child("color").val()

})
     
     });
     // console.log( this.statsCards.length);
     this.statsCards.reverse();

    });
 this.statsCards.forEach(async (post,index) => {
let wasliked= await firebase.database().ref('users')
.child(this.uid)
.child("liked").child(post.key).once("value");
 this.statsCards[index].liked = wasliked.numChildren()>0?"success":"info"
if(wasliked.numChildren()>0){
this.liked.push(post.key);
}

 });
 this.forceRerender();


  
}
    ,

     methods:{
       forceRerender() {
      console.log(" render ");
      this.componentKey += 1;
    },
       handleLike(index,uid) {

if(this.liked.includes(index)){

var index1 = this.liked.indexOf(index);
this.liked.splice(index1, 1);

firebase.database().ref('post').child(index).update({
  like:firebase.database.ServerValue.increment(-1)

})
firebase.database().ref('users').child(this.uid).child("liked").child(index).remove();

}else{


this.liked.push(index);
firebase.database().ref('post').child(index).update({
  like:firebase.database.ServerValue.increment(1)

})

firebase.database().ref('users').child(this.uid).child("liked").child(index).set({
  like:uid
})
}

    },
    checkliked(value) {
        let val = (value/1).toFixed(2).replace(',', '.')
        return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")
    },
       formatPrice(value) {
        let val = (value/1).toFixed(2).replace(',', '.')
        return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")
    },
 color(color){
     var time = 'badge badge-pill badge-' ;

  switch (color) {
    case "info":
      time=time+"info";
      break;
      case "success":
      time=time+"success";
      break;
      case "danger":
      time=time+"danger";
      break;
  
    default:
      break;
  }
  return time;
},
 timeConverter(UNIX_timestamp){
  var a = new Date(UNIX_timestamp);
  var months = ['Jan','Feb','Mar','Apr','May','Jun','Jul','Aug','Sep','Oct','Nov','Dec'];
  var year = a.getFullYear();
  var month = months[a.getMonth()];
  var date = a.getDate();
  var hour = a.getHours();
  var min = a.getMinutes();
  var sec = a.getSeconds();
  var time = date + ' ' + month + ' ' + year + ' ' + hour + ':' + min + ':' + sec ;
  return time;
},

     }
};
</script>
<style></style>
