<template>
    <div>
      <h1>Managers</h1>
      <button class="btn btn-success btn-sm" style="margin-bottom:1em" @click="addNewManager">Add New Manager</button>
      <table class="table table-hover table-dark">
        <thead>
          <tr>
            <th style="width: 20%;" scope="col">Name</th>
            <th style="width: 20%;" scope="col">Email</th>
            <th style="width: 20%;" scope="col">Phone</th>
            <th style="width: 20%;" scope="col">Actions</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(manager, index) in managers" :key="manager.id">
            <td><input style="width: 90%;"  v-if="manager.editing" v-model="manager.name"><span v-else class="readonly">{{ manager.name }}</span></td>
            <td><input style="width: 90%;" v-if="manager.editing" v-model="manager.email"><span v-else class="readonly">{{ manager.email }}</span></td>
            <td><input style="width: 90%;" v-if="manager.editing" v-model="manager.phone"><span v-else class="readonly">{{ manager.phone }}</span></td>
           
              
            
            <td>
              <button type="button" class="btn btn-warning btn-sm" style="width: 90px; height: 30px;" @click="saveManager(index),reload()" v-show="manager.editing">Save</button>
              <button type="button" class="btn btn-secondary btn-sm" style="width: 90px; height: 30px;" @click="editManager(index)" v-show="!manager.editing && !manager.deleting">Edit</button>
              <button type="button" class="btn btn-secondary btn-sm" style="width: 90px; height: 30px;" @click="cancelEditManager(),reload()" v-show="manager.editing">Cancel</button>
              <button type="button" class="btn btn-danger btn-sm" style="width: 90px; height: 30px;" @click="deleteManager(index)" v-show="!manager.editing && !manager.deleting">Delete</button>
              <button type="button" class="btn btn-danger btn-sm" style="width: 90px; height: 30px;" @click="confirmDeleteManager(index),reload()" v-show="manager.deleting">Confirm</button>
              <button type="button" class="btn btn-secondary btn-sm" style="width: 90px; height: 30px;" @click="cancelDeleteManager(index),reload()" v-show="manager.deleting">Cancel</button>
            </td>
          </tr>
          <tr v-show="addingNewManager">
            <td><input style="width: 90%;" type="text" v-model="newManager.name" /></td>
            <td><input style="width: 90%;" type="text" v-model="newManager.email" /></td>
            <td><input style="width: 90%;" type="text" v-model="newManager.phone" /></td>
            <td>
              <button class="btn btn-warning btn-sm" style="width: 90px; height: 30px;" @click="saveNewManager(),reload()">Save</button>
              <button class="btn btn-secondary btn-sm" style="width: 90px; height: 30px;" @click="cancelAddManager()">Cancel</button>
            </td>
          </tr>
        </tbody>
      </table>
      <h5></h5>
    </div>
  </template>
<script>
import supabase from "@/supabase.js";

export default {
  name: "managers",
  data() {
    return {
      managers: [],
      addingNewManager: false,
      newManager: {
        name: "",
        email: "",
        phone: "",
       
      },
    };
  },
  async mounted() {
    try {
      const { data, error } = await supabase.from("managers").select("*");

      if (error) {
        throw error;
      }

      console.log(data);
      this.managers = data.map((manager) => ({
        ...manager,
        editing: false,
        deleting: false, 
      }));
    } catch (error) {
      console.log(error);
    }
  },
  methods: {
    async reload() {
    setTimeout(async () => {
    try {
      const { data, error } = await supabase.from("managers").select("*");

      if (error) {
        throw error;
      }

      console.log(data);
      this.managers = data.map((manager) => ({
        ...manager,
        editing: false,
        deleting: false, 
      }));
    } catch (error) {
      console.log(error);
    }
  }, 500); 
},

    async saveManager(index) {
      try {
        const manager = this.managers[index];
        const { error } = await supabase
          .from("managers")
          .update({
            name: manager.name,
            email: manager.email,
            phone: manager.phone,
          })
          .eq("id", manager.id);

        if (error) {
          throw error;
        }

        manager.editing = false;
      } catch (error) {
        console.log(error);
      }
    },
    editManager(index) {
      const manager = this.managers[index];
      manager.editing = true;
    },
    deleteManager(index) {
        const manager = this.managers[index];
        manager.deleting = true; 
    },
    async cancelDeleteManager(index) {
        const manager = this.managers[index];
        manager.deleting = false; 
    },
    
    async confirmDeleteManager(index) {
      try {
        const manager = this.managers[index];
        const { error } = await supabase
          .from("managers")
          .delete()
          .eq("id", manager.id);

        if (error) {
          throw error;
        }

        this.managers.splice(index, 1);
      } catch (error) {
        console.log(error);
      }
    },
    async addNewManager() {
      this.addingNewManager = true;
    },
    async saveNewManager() {
  try {
    const { error } = await supabase
      .from("managers")
      .insert([this.newManager]);

    if (error) {
      throw error;
    }

    
    this.addingNewManager = false;

    // Reset newManager object
    this.newManager = {
      name: "",
      email: "",
      phone: "",
    };
  } catch (error) {
    console.log(error);
  }
},

    updateManagerField(index, field, value) {
      this.managers[index][field] = value;
    },
    cancelEditManager() {
        this.managers.forEach((manager) => {
    manager.editing = false;
  });
    },
    cancelAddManager() {
  this.addingNewManager = false;
  this.newManager = {
    name: "",
    email: "",
    phone: "",
  };
},
    
  },
};
</script>