<template>
    <div class="row">
          <div class="col-md-12">
    <ValidationObserver v-slot="{ handleSubmit }">
    <form @submit.prevent="handleSubmit(submit)">
      <card footer-classes="text-right">
    
          <div slot="header"><h4 class="card-title">New Building Subdivision</h4></div>
          <div>
          <div>
            <div class="form-group col-md-12">
                <ValidationProvider
                  name="Building"
                  rules="required"
                  v-slot="{ passed, failed, errors }"
                >
                  <label>Building</label>
                  <el-select
                    required
                    type="select"
                    placeholder="Select Building"
                    v-model="selects.building"
                    :error="errors[0]"
                    :class="[{ 'has-success': passed }, { 'has-danger': failed }]"
                  >
                    <el-option
                      v-for="option in selects.buildings"
                      :value="option.value"
                      :label="option.label"
                      :key="option.label"
                    ></el-option>
                  </el-select>
                </ValidationProvider>
            </div>
            <div class="col-md-12">
                <h4>Subdivision</h4>
                <div class="row">
                  <div v-for="(subdivision, index) in subdivisions" :key="index" class="col-md-12 ">
                    <div class="row">
                      <div class="col-md-7">
                        <ValidationProvider
                          :name="'Subdivision ' + (index + 1)"
                          rules="required"
                          v-slot="{ passed, failed, errors }"
                        >
                          <base-input
                            required
                            type="text"
                            :placeholder="'Subdivision ' + (index + 1)"
                            v-model="subdivision.value"
                            :error="errors[0]"
                            :class="[{ 'has-success': passed }, { 'has-danger': failed }]"
                          ></base-input>
                        </ValidationProvider>
                      </div>
                      <div class="">
                        <div class="input-group-append">
                          <base-button
                            type="link"
                            @click="removeSubdivision(index)"
                          >
                            <i class="fas fa-times text-danger"></i>
                          </base-button>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="col-md-12">
                    <div>
                      <base-button @click="addSubdivision" type="primary" class="btn-sm"><i class="fas fa-plus"></i></base-button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="text-right">
                <base-button class="mt-3" native-type="submit" type="primary">Save</base-button>
              </div>
              
          </div>

        </card>
      </form>
    </ValidationObserver>
  
  <div class="row mt-5">
    <div class="col-md-12">
      <card card-body-classes="table-full-width">
        <div><h2 class="text-center">Subdivisions</h2>
          <div
            class="col-12 d-flex justify-content-center justify-content-sm-between flex-wrap"
          >
            <el-select
              class="select-primary mb-3 pagination-select"
              v-model="pagination.perPage"
              placeholder="Per page"
            >
              <el-option
                class="select-primary"
                v-for="item in pagination.perPageOptions"
                :key="item"
                :label="item"
                :value="item"
              >
              </el-option>
            </el-select>

            <base-input>
              <el-input
                type="search"
                class="mb-3 search-input"
                clearable
                prefix-icon="el-icon-search"
                placeholder="Search buildings"
                v-model="searchQuery"
                aria-controls="datatables"
              >
              </el-input>
            </base-input>
          </div>
          <el-table :data="queriedData">
            <el-table-column
              v-for="column in tableColumns"
              :key="column.label"
              :min-width="column.minWidth"
              :prop="column.prop"
              :label="column.label"
            >
            </el-table-column>
            <el-table-column :min-width="90" align="right" label="Actions">
              <div slot-scope="props">
                <base-button
                  @click.native="handleEdit(props.$index, props.row)"
                  class="edit btn-link"
                  type="warning"
                  size="sm"
                  icon
                >
                  <i class="tim-icons icon-pencil"></i>
                </base-button>
              </div>
            </el-table-column>
          </el-table>
        </div>
        <div
          slot="footer"
          class="col-12 d-flex justify-content-center justify-content-sm-between flex-wrap"
        >
          <div class="">
            <p class="card-category">
              Showing {{ from + 1 }} to {{ to }} of {{ total }} entries
            </p>
          </div>
          <base-pagination
            class="pagination-no-border"
            v-model="pagination.currentPage"
            :per-page="pagination.perPage"
            :total="total"
          >
          </base-pagination>
        </div>
      </card>
    </div>
  </div>
  <modal 
  :show.sync="searchModalVisible"
  
  id="searchModal2 "
  :centered="false"
  :show-close="true"
  
>
<div>
  <div class="modal-header">
    <h2 class="modal-title" style="color: white;">Edit Subdivision</h2>
    <button type="button" class="close" @click="searchModalVisible = false">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <form>
  <div >
    <div class="form-group">
        <label for="name">Name:</label>
        <input type="text" class="form-control" v-model="selectedRowCopy.name" id="name" readonly>
      </div>      
    <div class="form-group">
        <label for="name">Subdivision:</label>
        <input type="text" class="form-control" v-model="selectedRowCopy.complement_1" id="complement_1">
    </div>
  </div>
  </form>
  <div class="modal-footer">
    <button type="button" class="btn btn-secondary" @click="searchModalVisible = false">Close</button>
    <button type="button" class="btn btn-primary" @click="handleSave()">Save changes</button>
  </div>
  </div>
</modal>
</div>
  </div>
  
     
  
  </template>
  <script>
  import { extend } from "vee-validate";
  import { required } from "vee-validate/dist/rules";
  import supabase from '@/supabase.js'

  import { Table, TableColumn, Select, Option } from 'element-ui';
  import { BasePagination ,Modal} from 'src/components';
  import Fuse from 'fuse.js';
  
  extend("required", required);
  
  export default {
    components: {
        Modal,
      BasePagination,
      [Select.name]: Select,
      [Option.name]: Option,
      [Table.name]: Table,
      [TableColumn.name]: TableColumn
    },
    computed: {
      queriedData() {
        let result = this.tableData;
        if (this.searchedData.length > 0) {
          result = this.searchedData;
        }
        return result.slice(this.from, this.to);
      },
      to() {
        let highBound = this.from + this.pagination.perPage;
        if (this.total < highBound) {
          highBound = this.total;
        }
        return highBound;
      },
      from() {
        return this.pagination.perPage * (this.pagination.currentPage - 1);
      },
      total() {
        return this.searchedData.length > 0
          ? this.searchedData.length
          : this.tableData.length;
      }
    },
    data() {
      return {
        email: "",
        selects: {
          building: "",
          buildings: [],
        },
        subdivisions: [
        { name: "Subdivision 1", value: "" },
      ],
      selectedRow: {},
          selectedRowCopy: {},
          pagination: {
            perPage: 5,
            currentPage: 1,
            perPageOptions: [5, 10, 25, 50],
            total: 0
          },
          id: 0,
          meterid: '',
          info: {},
          searchModalVisible: false,
          searchQuery: '',
          propsToSearch: ['name', 'complement_1'],
          tableColumns: [
            {
              prop: 'name',
              label: 'Building',
              minWidth: 200
            },
            {
              prop: 'complement_1',
              label: 'Subdivision',
              minWidth: 150
            },
          ],
          tableData: [],
          searchedData: [],
          fuseSearch: null
      };
    },
    async created() {
      await this.getBuildings();
    },

    async mounted() {
        await this.loadData ();
    },
    watch: {
        searchQuery(value) {
        this.searchedData = [];
        let result = this.tableData;
        if (value !== '') {
          const options = {
            caseSensitive: false,
            includeScore: false,
            shouldSort: true,
            shouldSortNumericStrings: true,
            threshold: 0.3,
            location: 0,
            distance: 100,
            useExtendedSearch: true,
            minMatchCharLength: 1,
            keys: [ 'name', 'complement_1'],
          };
          const fuse = new Fuse(result, options);
          result = fuse.search(value).map(r => r.item);
          result.forEach(note => {
            this.searchedData.push(note);
          });
        }
      }

    },
    methods: {   
        async loadData (){
            const { data: { user }, error } = await supabase.auth.getUser();
            if (error) {
                console.error(error);
                return;
            }

            const userId = user.id;
            console.log('User ID:', userId);

            const { data: permissionData, error: permissionError } = await supabase
                .from('permissionbusinessmanagers')
                .select('id_building')
                .eq('id_businessmanager', userId);

            if (permissionError) {
                throw permissionError;
            }

            const buildingIds = permissionData.map(permission => permission.id_building);

            const { data: buildingData, error: buildingError } = await supabase
                .from('buildings')
                .select('id, name')
                .in('id', buildingIds);

            if (buildingError) {
                throw buildingError;
            }

            const buildingNamesMap = buildingData.reduce((acc, building) => {
                acc[building.id] = building.name;
                return acc;
            }, {});

            const { data: buildingSubdivisionsData, error: buildingSubdivisionsError } = await supabase
                .from('buildingsubdivision')
                .select('id_building, complement_1, id')
                .in('id_building', buildingIds);

            if (buildingSubdivisionsError) {
                throw buildingSubdivisionsError;
            }

            this.tableData = buildingSubdivisionsData.map(buildingSubdivision => ({
                id_building: buildingSubdivision.id_building,
                name: buildingNamesMap[buildingSubdivision.id_building],
                complement_1: buildingSubdivision.complement_1,
                id_subdivision:buildingSubdivision.id,
            }));

            this.fuseSearch = new Fuse(this.tableData, {
                keys: ['id_building','name', 'complement_1','id_subdivision'],
                threshold: 0.3
            });
            },


        async getBuildings() {
            const { data: { user }, error } = await supabase.auth.getUser();
            if (error) {
                console.error(error);
                return;
            }
            const userId = user.id;
            //console.log('User ID:', userId);
            //console.log(user)

        
            const { data: permissionData, error: permissionError } = await supabase
            .from("permissionbusinessmanagers")
            .select("id_building")
            .eq("id_businessmanager", userId);
    
                if (permissionError) {
                throw permissionError;
                }
    
            const { data: buildingData, error: buildingError } = await supabase
            .from("buildings")
            .select("*")
            .in("id", permissionData.map((permission) => permission.id_building));
    
                if (buildingError) {
                throw buildingError;
                }
    
            this.selects.buildings = buildingData.map((building) => ({
            value: building.id,
            label: building.name,
            }));
        },
  
  
        async submit() {

            const id_building = this.selects.building;
            console.log(id_building);
            console.log(this.subdivisions)

            let subdivisoesInseridas = 0;
            let subdivisoesNaoInseridas = [];

            for (const subdivision of this.subdivisions) {
            const { error } = await supabase
                .from('buildingsubdivision')
                .insert({ complement_1: subdivision.value, id_building });

            if (error) {
                if(error.code === '23505'){
                    subdivisoesNaoInseridas.push(subdivision.value);
                    continue;
                }
                console.log(error.message);
                return;
            }
            subdivisoesInseridas++;
            }

            let mensagem = `Foram inseridas ${subdivisoesInseridas} subdivisões`;

            if (subdivisoesNaoInseridas.length > 0) {
            mensagem += ` e as seguintes subdivisões já estavam registradas: ${subdivisoesNaoInseridas.join(', ')}.`;
            }

            alert(mensagem);
            await this.loadData ();

            //this.selects.building = '';
            //this.subdivision.value = '';
        },

        addSubdivision() {
            this.subdivisions.push({ value: "" });
        },

        removeSubdivision(index) {
            if (this.subdivisions.length > 1) {
            this.subdivisions.splice(index, 1);
            }
        },

        handleEdit(index, row) {
            this.selectedRow = row;
            this.selectedRowCopy = { ...row };
            this.searchModalVisible = true;
            console.log(row.id)
            },

            async handleSave() {
        try {
            const { error } = await supabase
            .from('buildingsubdivision')
            .update({
                complement_1: this.selectedRowCopy.complement_1,
            })
            .eq('id', this.selectedRowCopy.id_subdivision);

            if (error) throw error;
            
            this.searchModalVisible = false;
            console.log('Building updated successfully');
            this.searchModalVisible = false;
                this.tableData = this.tableData.map(item => {
                if (item.id_subdivision === this.selectedRowCopy.id_subdivision) {
                    return this.selectedRowCopy;
                }
                return item;
                });
                this.selectedRow = {};

        } catch (error) {
            console.error(error); 
            console.log('Error updating subdivision');
        }
        },
      
    }
  
  };
  </script>
<style>
.pagination-select,
  .search-input {
    width: 200px;
  }
  .swal2-icon-content{
    font-size: inherit !important;
  }
  .modal-body  {
    background-color: #27293D; 
    
  }
  .modal-title{
    padding-bottom: 8%;
  }
  .el-table th.el-table__cell {
    
    background-color: rgba(255, 255, 255, 0);
}
</style>
  