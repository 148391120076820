<template>
    <div class="content">
      <div class="col-md-8 ml-auto mr-auto">
        <h2 class="text-center">Buldings</h2>
      </div>
      <div class="row mt-5">
        <div class="col-12">
          <card card-body-classes="table-full-width">
            <div>
              <div
                class="col-12 d-flex justify-content-center justify-content-sm-between flex-wrap"
              >
                <el-select
                  class="select-primary mb-3 pagination-select"
                  v-model="pagination.perPage"
                  placeholder="Per page"
                >
                  <el-option
                    class="select-primary"
                    v-for="item in pagination.perPageOptions"
                    :key="item"
                    :label="item"
                    :value="item"
                  >
                  </el-option>
                </el-select>
  
                <base-input>
                  <el-input
                    type="search"
                    class="mb-3 search-input"
                    clearable
                    prefix-icon="el-icon-search"
                    placeholder="Search records"
                    v-model="searchQuery"
                    aria-controls="datatables"
                  >
                  </el-input>
                </base-input>
              </div>
              <el-table :data="queriedData">
                <el-table-column
                  v-for="column in tableColumns"
                  :key="column.label"
                  :min-width="column.minWidth"
                  :prop="column.prop"
                  :label="column.label"
                >
                </el-table-column>
                <el-table-column :min-width="90" align="right" label="Actions">
                  <div slot-scope="props">
                    <base-button
                      @click.native="handleEdit(props.$index, props.row)"
                      class="edit btn-link"
                      type="warning"
                      size="sm"
                      icon
                    >
                      <i class="tim-icons icon-pencil"></i>
                    </base-button>
                    <!-- <base-button
                      @click.native="handleDelete(props.$index, props.row)"
                      class="remove btn-link"
                      type="danger"
                      size="sm"
                      icon
                    >
                      <i class="tim-icons icon-simple-remove"></i>
                    </base-button> -->
                  </div>
                </el-table-column>
              </el-table>
            </div>
            <div
              slot="footer"
              class="col-12 d-flex justify-content-center justify-content-sm-between flex-wrap"
            >
              <div class="">
                <p class="card-category">
                  Showing {{ from + 1 }} to {{ to }} of {{ total }} entries
                </p>
              </div>
              <base-pagination
                class="pagination-no-border"
                v-model="pagination.currentPage"
                :per-page="pagination.perPage"
                :total="total"
              >
              </base-pagination>
            </div>
          </card>
        </div>
      </div>
      <modal 
      :show.sync="searchModalVisible"
      
      id="searchModal2 "
      :centered="false"
      :show-close="true"
      
    >
    <div>
      <div class="modal-header">
        <h2 class="modal-title" style="color: white;">Edit Building</h2>
        <button type="button" class="close" @click="searchModalVisible = false">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <form>
      <div >
        <div class="form-group">
          <label for="name">Name:</label>
          <input type="text" class="form-control" v-model="selectedRowCopy.name" id="name">
        </div>

        <div class="form-row">
          <div class="form-group col-md-5">
            <label for="street">Street:</label>
            <input type="text" class="form-control" v-model="selectedRowCopy.street" id="street">
          </div>
          <div class="form-group col-md-3">
            <label for="number">Number:</label>
            <input type="text" class="form-control" v-model="selectedRowCopy.number" id="number">
          </div>
          <div class="form-group col-md-4">
            <label for="neighborhood">Neighborhood:</label>
            <input type="text" class="form-control" v-model="selectedRowCopy.neighborhood" id="neighborhood">
          </div>
        </div>

        <div class="form-row">
          <div class="form-group col-md-5">
            <label for="city">City:</label>
            <input type="text" class="form-control" v-model="selectedRowCopy.city" id="city">
          </div>
          <div class="form-group col-md-3">
            <label for="state">State:</label>
            <input type="text" class="form-control" v-model="selectedRowCopy.state" id="state">
          </div>
          <div class="form-group col-md-4">
            <label for="zipcode">Zipcode:</label>
            <input type="text" class="form-control" v-model="selectedRowCopy.zipcode" id="zipcode">
          </div>
        </div>

        <div class="form-row">
          <div class="form-group col-md-6">
            <label for="total_units">Total Units:</label>
            <input type="number" class="form-control" v-model="selectedRowCopy.total_units" id="total_units">
          </div>
          <div class="form-group col-md-6">
            <label for="total_subdivisions">Total Subdivisions:</label>
            <input type="number" class="form-control" v-model="selectedRowCopy.total_subdivisions" id="total_subdivisions">
          </div>
        </div>

      </div>
      </form>
      <div class="modal-footer">
        <button type="button" class="btn btn-secondary" @click="searchModalVisible = false">Close</button>
        <button type="button" class="btn btn-primary" @click="handleSave()">Save changes</button>
      </div>
      </div>
    </modal>
    
    

    
  
      </div>
  </template>
  <script>
  
  import { Table, TableColumn, Select, Option } from 'element-ui';
  import { BasePagination ,Modal} from 'src/components';
  import Fuse from 'fuse.js';
  import supabase from '@/supabase.js'

  
  export default {
    components: {
      Modal,
      BasePagination,
      [Select.name]: Select,
      [Option.name]: Option,
      [Table.name]: Table,
      [TableColumn.name]: TableColumn
    },
    computed: {
      /***
       * Returns a page from the searched data or the whole data. Search is performed in the watch section below
       */
      queriedData() {
        let result = this.tableData;
        if (this.searchedData.length > 0) {
          result = this.searchedData;
        }
        return result.slice(this.from, this.to);
      },
      to() {
        let highBound = this.from + this.pagination.perPage;
        if (this.total < highBound) {
          highBound = this.total;
        }
        return highBound;
      },
      from() {
        return this.pagination.perPage * (this.pagination.currentPage - 1);
      },
      total() {
        return this.searchedData.length > 0
          ? this.searchedData.length
          : this.tableData.length;
      }
    },
    data() {
        return {
          selectedRow: {},
          selectedRowCopy: {},
          pagination: {
            perPage: 5,
            currentPage: 1,
            perPageOptions: [5, 10, 25, 50],
            total: 0
          },
          id: 0,
          meterid: '',
          info: {},
          searchModalVisible: false,
          searchQuery: '',
          propsToSearch: ['name', 'street', 'number', 'neighborhood', 'city', 'state', 'zipcode', 'total_units', 'total_subdivisions'],
          tableColumns: [
            {
              prop: 'name',
              label: 'Name',
              minWidth: 200
            },
            {
              prop: 'street',
              label: 'Street',
              minWidth: 150
            },
            {
              prop: 'number',
              label: 'Number',
              minWidth: 60
            },
            {
              prop: 'city',
              label: 'City',
              minWidth: 150
            },
            {
              prop: 'state',
              label: 'State',
              minWidth: 50
            },
            {
              prop: 'zipcode',
              label: 'ZIP',
              minWidth: 100
            },
            {
              prop: 'total_units',
              label: 'Total Units',
              minWidth: 100
            },
            {
              prop: 'total_subdivisions',
              label: 'Total Subdivisions',
              minWidth: 120
            }
          ],
          tableData: [],
          searchedData: [],
          fuseSearch: null
        };

    },

    async mounted() {
        
    const { data: { user }, error } = await supabase.auth.getUser();

    if (error) {
        console.error(error);
        return;
    }
    const userId = user.id;
    console.log('User ID:', userId);

    const { data: permissionData, error: permissionError } = await supabase
    .from('permissionbusinessmanagers')
    .select('id_building')
    .eq('id_businessmanager', userId);

    if (permissionError) {
    throw permissionError;
    }

    const buildingIds = permissionData.map(permission => permission.id_building);

    const { data: buildingData, error: buildingError } = await supabase
    .from('buildings')
    .select(' id, name, street, number, neighborhood, city, state, zipcode, total_units, total_subdivisions')
    .in('id', buildingIds);

    if (buildingError) {
    throw buildingError;
    }

    this.tableData = buildingData.map(building => ({
    id: building.id,
    name: building.name,
    street: building.street,
    number: building.number,
    neighborhood: building.neighborhood,
    city: building.city,
    state: building.state,
    zipcode: building.zipcode,
    total_units: building.total_units,
    total_subdivisions: building.total_subdivisions,
    }));

    this.fuseSearch = new Fuse(this.tableData, {
    keys: [ 'name', 'street', 'number', 'neighborhood', 'city', 'state', 'zipcode', 'total_units', 'total_subdivisions'],
    threshold: 0.3
    });
    },

    watch: {
      searchQuery(value) {
        this.searchedData = [];
        let result = this.tableData;
        if (value !== '') {
          const options = {
            caseSensitive: false,
            includeScore: false,
            shouldSort: true,
            shouldSortNumericStrings: true,
            threshold: 0.3,
            location: 0,
            distance: 100,
            useExtendedSearch: true,
            minMatchCharLength: 1,
            keys: [ 'name', 'street', 'number', 'neighborhood', 'city', 'state', 'zipcode', 'total_units', 'total_subdivisions'],
          };
          const fuse = new Fuse(result, options);
          result = fuse.search(value).map(r => r.item);
          result.forEach(note => {
            this.searchedData.push(note);
          });
        }
      }

    },
    methods: {
      handleEdit(index, row) {
      this.selectedRow = row;
      this.selectedRowCopy = { ...row };
      this.searchModalVisible = true;
      console.log(row.id)
      },

      async handleSave() {
  try {
    const { error } = await supabase
      .from('buildings')
      .update({
        name: this.selectedRowCopy.name,
        street: this.selectedRowCopy.street,
        number: this.selectedRowCopy.number,
        neighborhood: this.selectedRowCopy.neighborhood,
        city: this.selectedRowCopy.city,
        state: this.selectedRowCopy.state,
        zipcode: this.selectedRowCopy.zipcode,
        total_units: this.selectedRowCopy.total_units,
        total_subdivisions: this.selectedRowCopy.total_subdivisions,
      })
      .eq('id', this.selectedRowCopy.id);

      if (error) throw error;
    
      this.searchModalVisible = false;
      console.log('Building updated successfully');
      this.searchModalVisible = false;
        this.tableData = this.tableData.map(item => {
          if (item.id === this.selectedRowCopy.id) {
            return this.selectedRowCopy;
          }
          return item;
          });
          this.selectedRow = {};

  } catch (error) {
    console.error(error); 
    console.log('Error updating building');
  }
},


}

  };
  </script>
  <style>
  .pagination-select,
  .search-input {
    width: 200px;
  }
  .swal2-icon-content{
    font-size: inherit !important;
  }
  .modal-body  {
    background-color: #27293D; 
    
  }
  .modal-title{
    padding-bottom: 8%;
  }
  .el-table th.el-table__cell {
    
    background-color: rgba(255, 255, 255, 0);
}
  
  </style>
  
  
  