<template>
  <div class="row">
    <div class="col-md-8"><edit-profile-form> </edit-profile-form></div>
    <div class="col-md-4">
      <!-- <div class="col-md-4 col-sm-4">
            <h4 class="card-title">Avatar</h4>
            <image-upload
              type="avatar"
              select-text="Add photo"
              @change="onAvatarChange"
            />
          </div> -->
       <user-card> </user-card>
      
      </div>
  </div>
</template>
<script>
import EditProfileForm from './UserProfile/EditProfileForm.vue';
import UserCard from './UserProfile/UserCard.vue';
import {
  
  ImageUpload,
  
} from 'src/components/index';

export default {
  components: {
    EditProfileForm,
    UserCard,
     ImageUpload
  },
  data() {
    return {
    
      images: {
        
        avatar: null
      },
    }
      
},
methods: {
    
    onAvatarChange(file) {
      this.images.avatar = file;
      console.log(file)
    }
  }
  };
</script>
<style></style>
