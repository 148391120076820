<template>
  <div>
    <div class="row"><route-bread-crumb /></div>
    <div class="row">
    <div class="col-md-6">
        <card  >
          <a slot="header">
            
          </a>
<span slot="header"  :class="color(statsCards.color)">{{statsCards.name}}</span>
          
          <p class="card-description">
            <p v-if="statsCards.save>0">
               I saved ${{formatPrice(statsCards.save)}} in energy
              </p>
              <p>
              {{statsCards.text}}
                </p>
         
          
           <hr />
{{statsCards.like}}
               <base-button
                @click.native="handleLike(statsCards.key,statsCards.uid)"                 
                    class="like btn-link"
                   :type="statsCards.liked"
                    size="sm"
                    icon
                  >
                    <i class="tim-icons icon-heart-2"></i>
                  </base-button>
 {{statsCards.comm}}
                   <base-button
                    
                    class="like btn-link"
                    type="info"
                    size="sm"
                    icon
                  >

                 <i class="tim-icons icon-chat-33"></i>
                    
                  </base-button>
 
                  <i class="ti-time">{{timeConverter(statsCards.timestamp)}}</i> 
               
        </card>
      </div>

      <div class="col-md-6">
      <card title="Horizontal Form">
        <h4 slot="header" class="card-title">Add your comment</h4>
        <form class="form-horizontal" action="#" method="#" @submit.prevent>
          <div class="row">
   
            <div class="col-md-12">
              <base-input v-model="comment" placeholder="Comment..."> </base-input>
            </div>
          </div>

        </form>
        <div class="row">
          <div class="col-md-9 offset-md-3 col-12">
            <base-button  @click.native="addcomment()" class="mt-3" native-type="submit" type="success">
              Add comment
            </base-button>
          </div>
        </div>
      </card>
    </div>
    </div>


   
      <div slot="header">
        <!-- <h4 class="card-title">Comments<div></div></h4> -->
        </div>
      <div class="row">
        <div class="col-sm-12">
          <el-table :data="tableData">
           <el-table-column min-width="150"  >
             <div
                slot-scope="{ row, $index }"
                
              >
              <card  >
          <a slot="header">
            
          </a>
<span slot="header"  class="badge badge-pill badge-success" >  {{row.name}} </span>
          
          <p class="card-description"  >
           {{row.text}} 
          </p>
          
           <hr />
{{row.like}}
               <base-button  
               @click.native="handleLikecomm(row.key,row.uid)"               
                    class="like btn-link"
                   :type="row.liked"
                    size="sm"
                    icon
                  >
                    <i class="tim-icons icon-heart-2"></i>
                  </base-button>
 
                  <i class="ti-time">{{timeConverter(row.timestamp)}}</i> 
               
        </card>
           </div>
            </el-table-column>
          </el-table>
        </div>
      </div>
    
   
    
  </div>
</template>
<script>
import { Table, TableColumn } from 'element-ui';
import { BaseCheckbox, BaseProgress, RouteBreadCrumb } from 'src/components';
import firebase from '../../Firebase';

export default {
  components: {
    [Table.name]: Table,
    [TableColumn.name]: TableColumn,
    BaseCheckbox,
    RouteBreadCrumb,
    BaseProgress,
  },
  
  data() {
    return {
      uid:"",
      liked: [],
      comment:"",
      name:"",
      statsCards:{},
      tableData: [
        // {
        //   id: 1,
        //   img: 'img/tania.jpg',
        //   name: 'Tania Mike',
        //   job: 'Develop hjbkbjkbkjbkj jkhkjhjkhkjhhkjhhj jkhjhkhjkhkhj ljjljljlk jjljkljlj jkhkjhjhkjhjhj ',
        //   progress: 25,
        //   since: 2013,
        //   salary: '€ 99,225'
        // },
       
       
      ],
     
    };
  },
  async created() {

     const currentUser = firebase.auth().currentUser
  this.uid = currentUser.uid;

 let wasliked= await firebase.database().ref('users')
.child(currentUser.uid)
.child("liked").child(this.$route.params.msg).once("value");
 this.statsCards.liked = wasliked.numChildren()>0?"success":"info"
if(wasliked.numChildren()>0){
this.liked.push(this.$route.params.msg);
}



    firebase.database().ref('post').child(this.$route.params.msg)
    .on("value",  note => {

var liked=this.liked.includes(note.ref.key)?"success":"info";
this.statsCards = {

          key:note.ref.key,
          name:note.child("name").val(),
          uid:note.child("uid").val(),
          inv: note.child("inv").val(),
          text: note.child("text").val(),
          save: note.child("save").val(),
          like: note.child("like").val(),
          comm:note.child("comm").val(),
          liked: liked,
          timestamp:note.child("timestamp").val(),
          icon:note.child("icon").val(),
          color:note.child("color").val()

}

    });

    firebase.database().ref('post').child(this.$route.params.msg).child("comments")
    .orderByChild('timestamp').on("value",  notes => {
      this.tableData= [];
     notes.forEach(  note => {
//this.liked.includes(note.ref.key)
var liked=this.liked.includes(note.ref.key)?"success":"info";

//let user = await firebase.database().ref('users').child(note.child("uid").val()).once("value");
//user.child("firstName").val()+" "+user.child("lastName").val()
this.tableData.push({

          key:note.ref.key,
          name:note.child("name").val(),
          uid:note.child("uid").val(),
          text: note.child("text").val(),
           like: note.child("like").val(),
           liked: liked,
          timestamp:note.child("timestamp").val(),
        

})
     
     });
     // console.log( this.statsCards.length);
      this.tableData.reverse();

    });

     this.tableData.forEach(async (post,index) => {
let wasliked= await firebase.database().ref('users')
.child(this.uid)
.child("liked").child(post.key).once("value");
 this.tableData[index].liked = wasliked.numChildren()>0?"success":"info"
if(wasliked.numChildren()>0){
this.liked.push(post.key);
}

 });


    firebase.database().ref('users').child(this.uid).on("value",user=>{

    this.name = user.child("firstName").val()+" "+user.child("lastName").val()
    
 });


   

    console.log(this.$route.params.msg)
        

    },
    methods:{
addcomment(){

  var dateyDate = new Date();

firebase.database().ref('post').child(this.$route.params.msg).child("comments").push({
text:this.comment,
timestamp:dateyDate.valueOf(),
uid:this.uid,
name:this.name,
like:0

})

firebase.database().ref('post').child(this.$route.params.msg).update({
  comm:firebase.database.ServerValue.increment(1)

})


  this.comment="";

},
forceRerender() {
      console.log(" render ");
      this.componentKey += 1;
    },
       handleLike(index,uid) {

if(this.liked.includes(index)){

var index1 = this.liked.indexOf(index);
this.liked.splice(index1, 1);

firebase.database().ref('post').child(index).update({
  like:firebase.database.ServerValue.increment(-1)

})
firebase.database().ref('users').child(this.uid).child("liked").child(index).remove();

}else{


this.liked.push(index);
firebase.database().ref('post').child(index).update({
  like:firebase.database.ServerValue.increment(1)

})

firebase.database().ref('users').child(this.uid).child("liked").child(index).set({
  like:uid
})
}

    },
     handleLikecomm(index,uid) {

if(this.liked.includes(index)){

var index1 = this.liked.indexOf(index);
this.liked.splice(index1, 1);

firebase.database().ref('post').child(this.$route.params.msg).child("comments").child(index).update({
  like:firebase.database.ServerValue.increment(-1)

})
firebase.database().ref('users').child(this.uid).child("liked").child(index).remove();

}else{


this.liked.push(index);
firebase.database().ref('post').child(this.$route.params.msg).child("comments").child(index).update({
  like:firebase.database.ServerValue.increment(1)

})

firebase.database().ref('users').child(this.uid).child("liked").child(index).set({
  like:uid
})
}

    },
    checkliked(value) {
        let val = (value/1).toFixed(2).replace(',', '.')
        return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")
    },
       formatPrice(value) {
        let val = (value/1).toFixed(2).replace(',', '.')
        return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")
    },
 color(color){
     var time = 'badge badge-pill badge-' ;

  switch (color) {
    case "info":
      time=time+"info";
      break;
      case "success":
      time=time+"success";
      break;
      case "danger":
      time=time+"danger";
      break;
  
    default:
      break;
  }
  return time;
},
 timeConverter(UNIX_timestamp){
  var a = new Date(UNIX_timestamp);
  var months = ['Jan','Feb','Mar','Apr','May','Jun','Jul','Aug','Sep','Oct','Nov','Dec'];
  var year = a.getFullYear();
  var month = months[a.getMonth()];
  var date = a.getDate();
  var hour = a.getHours();
  var min = a.getMinutes();
  var sec = a.getSeconds();
  var time = date + ' ' + month + ' ' + year + ' ' + hour + ':' + min + ':' + sec ;
  return time;
},

    }
};
</script>
<style lang="scss">
.checkbox-cell {
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
}
</style>
