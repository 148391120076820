<template>
  <card>
    <h5 slot="header" class="title">Edit Profile</h5>
    <form @submit.prevent="updateProfile">
      <div class="row">
        <div class="col-md-5">
          <base-input
            type="text"
            label="Company"
           
            placeholder="Company"
            v-model="user.company"
          >
          </base-input>
        </div>
        <div class="col-md-3">
          <base-input
            type="text"
            label="Building size"
            placeholder="Building size in sq. ft."
            v-model="user.size"
          >
          </base-input>
        </div>
        <div class="col-md-4">
          <base-input
            type="email"
             :disabled="true"
            label="Email address"
            placeholder="email"
            v-model="user.email"
          >
          </base-input>
        </div>
      </div>

      <div class="row">
        <div class="col-md-12">
          <base-input
            type="text"
            label="Name"
            placeholder=" Name"
            v-model="user.name"
          >
          </base-input>
        </div>
       
      </div>

      <div class="row">
        <div class="col-md-12">
          <base-input
            type="text"
            label="Address"
            placeholder="Home Address"
            v-model="user.address"
          >
          </base-input>
        </div>
      </div>

      <div class="row">
        <div class="col-md-4">
          <base-input
            type="text"
            label="City"
            placeholder="City"
            v-model="user.city"
          >
          </base-input>
        </div>
        <div class="col-md-4">
          <base-input
            type="text"
            label="State/Country"
            placeholder="State/Country"
            v-model="user.state"
          >
          </base-input>
        </div>
        <div class="col-md-4">
          <base-input
            label="Postal Code"
            placeholder="ZIP Code"
            v-model="user.zipcode"
          >
          </base-input>
        </div>
      </div>

      <div class="row">
        <div class="col-md-12">
          <base-input label="About Me">
            <textarea
              class="form-control"
              placeholder="..."
              v-model="user.about"
            >
            </textarea>
          </base-input>
        </div>
      </div>

      <base-button native-type="submit" type="primary" class="btn-fill">
        Save
      </base-button>
    </form>
  </card>

  
</template>
<script>

import supabase from '@/supabase.js'



export default {
  data() {
    return {
       uid:"",
      user: {
        company: '',
        size: '',
        email: '',
        Name: '',
        address: '',
        city: '',
        state: '',
        zipcode: '',
        about: ''
      }
    };
  },
  async created(){
    

    

       const { data, error } = await supabase
  .from('customers')
  .select('*')
  .eq('id', this.$user.id).single()

  //console.log("user",data);

  this.user=data;


  },

  methods: {
    async updateProfile() {
     // alert('Your data: ' + JSON.stringify(this.user));

     const { error } = await supabase
  .from('customers')
  .update(this.user)
  .eq('id', this.$user.id)


      alert('Your profile has been uptaded!');

      
    }
  }
};
</script>
<style></style>
