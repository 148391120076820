<template>
  <div class="row">
    <div class="col-md-12">


      <modal 
            :show.sync="searchModalVisible"
            
            id="searchModal2 "
            :centered="false"
            :show-close="true"
            
            >
            <div>
            <div class="modal-header" style="color: #3e2424;">
                <h2 class="modal-title" >Notifications</h2>
                <button type="button" class="close" @click="searchModalVisible = false">
                <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <div >
                <div class="form-group">
                  <div class="col-md-10">
            <h4 class="card-title">Phone Numbers</h4>

            <tags-input v-model="tags" @change="phones" tag-type="info" ncolor="color: black" ></tags-input>
          </div>
                
                </div>
            </div>
            
            <div class="modal-footer">
                <button type="button" class="btn btn-secondary"  @click="handleClose">Close</button>
                <button type="button" class="btn btn-primary" @click="handleSave(selectedRow.id)">Save changes</button>
            </div>
            </div>
            </modal>


      <div class="card">
        <div class="row">
          <div class="col-md-6">
                <div class="card-header">
<h4 class="card-title"> Alarm Set Up</h4>
                  </div>

                  <div class="card-header">
            <el-select class="select-info"
             placeholder="Meter"
             v-model="selects.meter" >
    <el-option v-for="option in selects.meters"
               class="select-info"
               :value="option.value"
               :label="option.label"
               :key="option.label">
    </el-option>
  </el-select>
 </div>


   <div class="card-header">
               <el-select multiple
             class="select-success"
             collapse-tags
             v-model="selects.day"
             placeholder="Select Days">
    <el-option v-for="option in selects.days"
               class="select-info"
               :value="option.value"
               :label="option.label"
               :key="option.label">
    </el-option>
  </el-select>
  </div>
            <div class="card-header">
              <h4 class="card-title">{{calcula(rangeSlider[0])+" - "+calcula(rangeSlider[1])}}</h4>
            <base-checkbox v-model="allday"
            @click.native="rangeSlider=[0, 1440]"
            :key="componentKey">All day</base-checkbox>
            </div>
            <div class="card-body">
              <slider v-model="rangeSlider"
              :range="{min: 0, max: 1440}"
          type="success"
          :options="{step: 5}"
          :connect="true"
           :key="componentKey">
  </slider>
            </div>
 <div class="card-header">
            <el-select class="select-info"
             placeholder="Trigger Type"
             v-model="selects.type" @change="someHandler">
    <el-option v-for="option in selects.types"
               class="select-info"
               :value="option.value"
               :label="option.label"
               :key="option.label">
    </el-option>
  </el-select>
 </div>
 <br>
          </div>
          <div class="col-md-6">
            <div class="card-header">
              <h4 class="card-title">Add Trigger</h4>
            </div>
            <div class="card-body">
    <div class="row">
<div class="col-sm-10">
              <div class="row">
                    <div class="col-md-6">
                  <input
           v-model="value"
          type="number"
          class="form-control"
          placeholder="Trigger value"
          @keypress="isNumber($event)"
          />
                </div>
 <div class="col-md-3">
                  {{selects.type}}
                </div>         
              </div>
            </div>        
               </div>     
               <div class="col-sm-10">
                     <div class="row">
              <base-button   @click.native="addalarm()"  type="success">
                <i class="tim-icons icon-check-2"></i> Add Alarm
              </base-button>
              </div>          
            </div>
            </div>
             <div class="col-md-10">
            <h4 class="card-title">Phone Numbers</h4>

            <tags-input v-model="tags" @change="phones" tag-type="info"></tags-input>
          </div>
          </div>
         
        </div>
      </div>

    
            
    </div>
     <card >
      <div slot="header"><h4 class="card-title">Alarms</h4></div>
     <div class="row">
     <div class="col-sm-12">
          <el-table :data="tableData">
            <!-- <el-table-column min-width="50" label="#" align="center">
              <div class="photo" slot-scope="{ row }">
                <img :src="row.img" alt="Table image" />
              </div>
            </el-table-column> -->
            <el-table-column min-width="110" prop="period" label="Period">
            </el-table-column>
            <!-- <el-table-column min-width="200" prop="job" label="Job Position">
            </el-table-column>
            <el-table-column
              min-width="150"
              prop="since"
              align="center"
              label="Since"
            >
          
            </el-table-column>  -->

            <el-table-column
              min-width="180"
              prop="desc"
              align="center"
              label="Meter"
            >
            </el-table-column> 

            <el-table-column
              min-width="180"
              prop="timeframe"
              align="center"
              label="Time Frame"
            >
            </el-table-column> 
            <el-table-column
              min-width="80"
              prop="value"
              align="center"
              label="Trigger"
            >
            </el-table-column> 
            <el-table-column
              min-width="70"
              prop="type"
              align="center"
              label="Type"
            >
            </el-table-column> 
            <el-table-column
              min-width="118"
              prop="timestamp"
              align="center"
              label="Created"
            >
            </el-table-column> 
            <el-table-column
              min-width="100"
              header-align="right"
              align="right"
              label="Actions"
            >
              <div
                slot-scope="{ row, $index }"
                class="text-right table-actions"
              >
                <el-tooltip
                  content="Phone Numbers"
                  effect="light"
                  :open-delay="300"
                  placement="top"
                >
                  <base-button
                    :type="$index > 2 ? 'success' : 'neutral'"
                    icon
                    size="sm"
                    class="btn-link"
                    @click.native="handleEdit(row)"
                  >
                    <i class="tim-icons icon-pencil"></i>
                  </base-button>
                </el-tooltip>
                <el-tooltip
                  content="Delete"
                  effect="light"
                  :open-delay="300"
                  placement="top"
                >
                  <base-button
                    :type="row.id > 2 ? 'danger' : 'neutral'"
                    icon
                    size="sm"
                    class="btn-link"
                    @click.native="showSwal('delete',row.id)"
                  >
                    <i class="tim-icons icon-simple-remove"></i>
                  </base-button>
                </el-tooltip>
              </div>
            </el-table-column>
          </el-table>
        </div>
</div>
 



    </card>


   

  </div>
</template>
<script>
import { Slider,TagsInput,Modal } from 'src/components';
import {Select, Option, Table, TableColumn,Tag } from 'element-ui'
import supabase from '@/supabase.js'
import moment from 'moment';
import swal from 'sweetalert2';


export default {
  components: {
     TagsInput,
     Modal,
    Slider,
    [Table.name]: Table,
    [TableColumn.name]: TableColumn,
    [Select.name]: Select,
    [Option.name]: Option
  },
  
  async created() {

    
// this.user = this.$user;

// this.tags= this.user.smsphone;


//this.$user.id

//console.log('user',this.$user.id);

this.reload();

  
const { data, error } = await supabase
  .from('user_devices')
  .select('*, devices(desc)')
  .eq('customer_id', this.$user.id)

  console.log('meters',data);
  data.forEach(info=>{

  //  this.selects.meter

    this.selects.meters.push({
      label:info.devices.desc,
      value:info.device_code
    })

  });

    
    },
  data() {
    return {
      searchModalVisible: false,
      selectedRow: {},
      user:{},
      tags: [],
         tableData: [],
        rangeSlider: [600, 950],
      value: '',
      allday:false,
      componentKey: 0,
       selects: {
           day:'',
           type:'',
           meter:'',
              days: 
              [{value: '10', label: 'Every Day'},
              {value: '9', label: 'Weekdays'},
              {value: '8', label: 'Weekends'},
              {value: '0', label: 'Sunday'},
              {value: '1', label: 'Monday'},
              {value: '2', label: 'Tuesday'},
              {value: '3', label: 'Wednesday'},
              {value: '4', label: 'Thursday'},
              {value: '5', label: 'Friday'},
              {value: '6', label: 'Saturday'},],
              types: 
              [
              {value: 'W', label: 'Power - W'},
              {value: 'Wh', label: 'Energy - Wh'}, 
               {value: '$h', label: 'Money per hour - $/h'},
              {value: '$', label: 'Money - $'},
             
               ],
               meters:[]
              
          }
       
    };
  },
  methods:{
    handleEdit(row) {
        this.selectedRow = row;
        this.tags=row.noti;
        this.searchModalVisible = true;
        console.log('alarm:',row)
        },
    phones(list){
      console.log(list)

      // this.tableData.forEach(alarms=>{

      //   console.log(alarms)
      //   firebase.database().ref("alarms").child(alarms.id).update({
      //     smsphone:list
      //   });
        
        

      // })

      //  firebase.firestore().collection('users').doc(this.uid).update({
      //   smsphone:list
      // })

    },
    async handleSave(id) {
        try {
                
        const { error } = await supabase
        .from('alarms')
        .update({ noti: this.tags})
  .eq('id', id)

        if (error){
            console.log(error.message);
            return;
        }

        this.searchModalVisible = false;
        console.log('alarms updated successfully');
            this.selectedRow = {};
            this.reload();

        } catch (error) {
        console.error(error); 
        console.log('Error updating alarms');
        }
        },
      periodtime(days){

let numday = ['10','9','8','0','1','2','3','4','5','6'];
let numdays = ['Every Day','Weekdays','Weekends','Sunday','Monday','Tuesday','Wednesday','Thursday','Friday','Saturday'];
var val = "";

for (let index = 0; index < 8; index++) {
    if(days[index]){
val +=numdays[numday.indexOf(days[index])]+" ";
    }
   
    
}

return val;

      }
      ,
      showSwal(type,id) {

if (type === 'delete') {
        swal.fire({
          title: 'Are you sure?',
          text: 'You will not be able to recover this alarm!',
          icon: 'warning',
          showCancelButton: true,
           buttons: true,
  dangerMode: true,
          confirmButtonText: 'Yes, delete it!',
          cancelButtonText: 'No, keep it',
          customClass: {
            confirmButton: 'btn btn-success btn-fill',
            cancelButton: 'btn btn-danger btn-fill'
          },
          buttonsStyling: false
        }).then(
          async (willDelete) => 
          {
         //   console.log(id);
            
          
            if(willDelete.value){

              const { error } = await supabase
  .from('alarms')
  .delete()
  .eq('id', id)


swal.fire({
              title: 'Deleted!',
              text: 'Your alarm has been deleted.',
              icon: 'success',
              customClass: {
                confirmButton: 'btn btn-success btn-fill'
              },
              buttonsStyling: false
            });

            this.reload();
            }else{
                      swal.fire({
                title: 'Cancelled',
                text: 'Your alarm file is safe :)',
                icon: 'error',
                customClass: {
                  confirmButton: 'btn btn-info btn-fill'
                },
                buttonsStyling: false
              });
            }
          //   dismiss => {
          //   // dismiss can be 'overlay', 'cancel', 'close', 'esc', 'timer'
          //   if (dismiss === 'close') {
          //     swal.fire({
          //       title: 'Cancelled',
          //       text: 'Your imaginary file is safe :)',
          //       icon: 'error',
          //       customClass: {
          //         confirmButton: 'btn btn-info btn-fill'
          //       },
          //       buttonsStyling: false
          //     });
          //   }
          // },
            
          }
        );
      }


     },
     async reload() {

      this.tableData=[];

      const { data, error } = await supabase
  .from('alarms')
  .select('*, devices(id,desc)')
  .eq('uid', this.$user.id)

  console.log('alarms',data);
  data.forEach(info=>{


    this.tableData.push({

id:info.id,
type:info.type,
desc:info.devices.desc,
period:this.periodtime(info.period),
timeframe: this.calcula(info.timeframe[0])+" - "+this.calcula(info.timeframe[1]),
value:info.value,
timestamp:this.timeConverter(info.created_at),
noti:info.noti
})



  })




     },
     handleClose() {
        
            this.searchModalVisible = false;
        },
      async addalarm() {
     // console.log(this.value+"//"+this.selects.type+"//"+this.selects.days);


     const { error } = await supabase
  .from('alarms')
  .insert({ 
    uid: this.$user.id, 
    period: this.selects.day, 
    timeframe: this.rangeSlider, 
    value: this.value, 
    type: this.selects.type, 
    device_id: this.selects.meter
  })
  

      // firebase.database().ref("alarms").push({
      //   meter: this.user.meter[0],
      //     uid:this.uid,
      //   period: this.selects.day,
      //   timeframe: this.rangeSlider,
      //   type:this.selects.type,
      //   value: this.value,
      //   timestamp:moment().unix()*1000
      // });

      this.value='';
       this.selects.day='';
        this.selects.type='';
        this.selects.meter='';
        this.reload();
    },
       timeConverter(UNIX_timestamp){
  var a = new Date(UNIX_timestamp);
  var months = ['Jan','Feb','Mar','Apr','May','Jun','Jul','Aug','Sep','Oct','Nov','Dec'];
  var year = a.getFullYear();
  var month = months[a.getMonth()];
  var date = a.getDate();
  var hour = a.getHours();
  var min = a.getMinutes();
  var sec = a.getSeconds();
  var time = date + ' ' + month + ' ' + year + ' ' + hour + ':' + min + ':' + sec ;
  return time;
},
      isNumber: function(evt) {
      evt = (evt) ? evt : window.event;
      var charCode = (evt.which) ? evt.which : evt.keyCode;
      if ((charCode > 31 && (charCode < 48 || charCode > 57)) && charCode !== 46) {
        evt.preventDefault();
      } else {
        return true;
      }
    },
      forceRerender() {
      console.log("settings render 1");
      this.componentKey += 1;
    },
    someHandler(){

if(this.selects.type==="$"||this.selects.type==="Wh"){
     this.allday = true;
     this.rangeSlider[0]=0;
     this.rangeSlider[1]=1440;
     this.forceRerender();
  }
    },
calcula(valorInicio){

 // console.log(valorInicio)
  
    //primeiro criei constantes para armazenar os valores dos tempos em MINUTOS. altere eles de acordo com sua necessidade
   
   if(this.allday===true&&this.rangeSlider[0]!==0&&this.rangeSlider[1]!==1440){
     if(this.selects.type==="$"||this.selects.type==="Wh"){
     this.allday = true;
     console.log("render2222")
    // this.forceRerender();
  }else{
this.allday = false;
 console.log("render1111")
this.forceRerender();
  }
 
   }
   

   

    
   //const minutos = 1;
    const horas = 60;
    var hora = 0;
    if (valorInicio > horas){
         hora = Math.floor(valorInicio / horas);
        valorInicio = valorInicio - (horas * hora);
    }else{
         hora = 0;
    }
var minuto = valorInicio; 

var hours = hora;
  var minutes = minuto;
  var ampm = hours >= 12 ? hora==24?'am':'pm' : 'am';
 hours = hours % 12;
  hours = hours > 0? hours : 12; // the hour '0' should be '12'
  minutes = minutes < 10 ? '0'+(minutes/1).toFixed(0) : (minutes/1).toFixed(0);
  var strTime = hours + ':' + minutes + ' ' + ampm;
  
  return strTime;
 }

  }
};
</script>
<style>
.pagination-select,
.search-input {
  width: 200px;
}
.swal2-icon-content{
  font-size: inherit !important;
}.el-table th.el-table__cell {
    
    background-color: rgba(255, 255, 255, 0);
}
</style>
