<template>
  <div class="container">
    <div class="row">
      <div class="col-md-6 ml-auto mr-auto text-center">
        <h1 class="title">Pick the best plan for you</h1>
        <h4 class="description">
          You have Free Unlimited Updates and Premium Support on each package.
        </h4>
      </div>
    </div>
    <div class="row">
      <div class="col-lg-3 col-md-6">
        <card
          type="pricing"
          class="card-primary"
          footer-classes="text-center mb-3 mt-3"
        >
          <h1 class="card-title">pro</h1>
          <img class="card-img" src="img/card-primary.png" alt="Image" />
          <ul class="list-group">
            <li class="list-group-item">Unlimited</li>
            <li class="list-group-item">Custom</li>
            <li class="list-group-item">Unlimited</li>
          </ul>
          <div class="card-prices">
            <h3 class="text-on-front"><span></span>Custom</h3>
            <h5 class="text-on-back">??</h5>
            <p class="plan">Professional plan</p>
          </div>
          <base-button slot="footer" round type="primary" class="btn-just-icon">
            Get started
          </base-button>
        </card>
      </div>

     

       <div class="col-lg-3 col-md-6">
        <card
          type="pricing"
          class="card-warning  card-white"
          footer-classes="text-center mb-3 mt-3"
        >
          
<h1 class="card-title">.S1</h1>
         <img class="card-img" src="img/card-danger.png" alt="Image" />
          <ul class="list-group">
            <li class="list-group-item">2 controls</li>
            <li class="list-group-item">Power metering</li>
            <li class="list-group-item">Alarm connected</li>
          </ul>
          <div class="card-prices">
            <h3 class="text-on-front"><span>$</span>250</h3>
            <h5 class="text-on-back">250</h5>
            <p class="plan">per device</p>
          </div>

           

          <base-button slot="footer" round type="warning" class="btn-just-icon">
            Get started
          </base-button>
        </card>
      </div> 


      <div class="col-lg-3 col-md-6">
        <card
          type="pricing"
          class="card-danger card-white"
          footer-classes="text-center mb-3 mt-3"
        >
          

          
          <h1 class="card-title">.M3</h1>
         
           <img class="card-img" src="img/card-warning.png" alt="Image" />
          <ul class="list-group">
           <li class="list-group-item">3 users</li>
            <li class="list-group-item">1 Smart Meters (3 phases)</li>
            <li class="list-group-item">3 Alarms</li>
          </ul>
          <div class="card-prices">
            <h3 class="text-on-front"><span>$</span>350 + <span>$</span>10/mo</h3>
            <h5 class="text-on-back">350</h5>
             <p class="plan">Medium plan</p>
            
          </div>

          <base-button slot="footer" round type="danger" class="btn-just-icon">
            Get started
          </base-button>
        </card>
      </div>

       <div class="col-lg-3 col-md-6">
        <card
          type="pricing"
          class="card-success card-raised card-white"
          footer-classes="text-center mb-3 mt-3"
        >
          <h1 class="card-title">..M1</h1>
          <img class="card-img" src="img/card-success.png" alt="Image" />
          <ul class="list-group">
            <li class="list-group-item">1 user</li>
            <li class="list-group-item">1 Smart Meter (2 phases)</li>
            <li class="list-group-item">1 Alarm</li>
          </ul>
          <div class="card-prices">
            <h3 class="text-on-front"><span>$</span>300 + <span>$</span>5/mo</h3>
            <h5 class="text-on-back">300</h5>
            <p class="plan">Basic plan</p>
          </div>

          <base-button slot="footer" round type="success" class="btn-just-icon">
            Get started
          </base-button>
        </card>
      </div>
    </div>
    <div class="col-md-12 mt-5"><h3 class="title">Free Installtion and no contract</h3></div>
    <div class="row">
      <div class="col-md-4">
        <p>
          All plans includes free installation and no contract, with a cancelation fee of $200 will apply.
        </p>
      </div>
      <div class="col-md-6 ml-auto">
        <base-progress
          type="warning"
          :show-value="false"
          badge="500GB"
          :value="75"
        />

        <base-progress
          type="primary"
          :show-value="false"
          badge="4 years"
          :value="50"
        />
      </div>
    </div>
  </div>
</template>
<script>
import { BaseProgress } from 'src/components';

export default {
  name: 'pricing-page',
  components: {
    BaseProgress
  }
};
</script>
<style></style>
