<template>
  <div>
    <div class="row d-flex justify-content-center">
      <div class="col-md-10">
        <simple-wizard>
          <template slot="header">
            <h3 class="card-title">Build your profile</h3>
            <h3 class="description">
              This information will let us know more about you to better assist you.
            </h3>
          </template>

          <wizard-tab :before-change="() => validateStep('step1')">
            <template slot="label">
              <i class="tim-icons icon-single-02"></i>
              <p>About</p>
            </template>
            <first-step
              ref="step1"
              @on-validated="onStepValidated"
              @on-validated1="onStepValidated1"
            ></first-step>
          </wizard-tab>

          <!-- <wizard-tab :before-change="() => validateStep('step2')">
            <template slot="label">
              <i class="tim-icons icon-settings-gear-63"></i>
              <p>Account</p>
            </template>
            <second-step
              ref="step2"
              @on-validated="onStepValidated"
            ></second-step>
          </wizard-tab> -->

          <wizard-tab :before-change="() => validateStep('step3')">
            <template slot="label">
              <i class="tim-icons icon-delivery-fast"></i>
              <p>Address</p>
            </template>
            <third-step ref="step3"
            @on-validated2="onStepValidated2"
            ></third-step>
          </wizard-tab>

          <wizard-tab :before-change="() => validateStep('step2')">
            <template slot="label">
              <i class="tim-icons icon-settings-gear-63"></i>
              <p>Account</p>
            </template>
            <second-step
              ref="step2"
              @on-validated3="onStepValidated3"
            ></second-step>
          </wizard-tab>
        </simple-wizard>
      </div>
    </div>
  </div>
</template>
<script>
import FirstStep from './Wizard/FirstStep.vue';
import SecondStep from './Wizard/SecondStep.vue';
import ThirdStep from './Wizard/ThirdStep.vue';
import swal from 'sweetalert2';
import { SimpleWizard, WizardTab } from 'src/components';
import firebase from '../../Firebase';

export default {
  data() {
    return {
      wizardModel: {},
      person:{},
      address:{},
      plan:{},
      devices:[]

    };
  },
  components: {
    FirstStep,
    SecondStep,
    ThirdStep,
    SimpleWizard,
    WizardTab
  },
  methods: {
    validateStep(ref) {
       console.log("Main:"+ref)
      //  this.wizardComplete()
      //  console.log(this.wizardModel)
      return this.$refs[ref].validate();
    // return true;
    },
    onStepValidated(validated, model) {
      // console.log(validated)
      // console.log(model)
     // console.log("Main"+validated+" / "+model)
      this.wizardModel = { ...this.wizardModel, ...model };
    },
    onStepValidated1(validated, model) {
      
      console.log(validated)
      this.person = validated
     // console.log(model)
    },
    onStepValidated2(validated, model) {

      console.log(validated)
       this.address = validated
     // this.wizardComplete()
     // console.log(model)
    },
    onStepValidated3(validated, model) {

      console.log("3t:"+validated)
      this.plan = validated.plan;
     this.devices = validated.devices;
      this.wizardComplete()
     // console.log(model)
    },
    wizardComplete() {
     // swal('Good job!', 'You clicked the finish button!', 'success');
firebase.firestore().collection('users').doc(this.$user.uid).update({
         plan:this.plan,
         firstName:this.person.firstName,
            lastName:this.person.lastName,
            phone:this.person.phone,
            address:this.address.address,
            compl:this.address.compl,
            city:this.address.city,
            country:this.address.country,
            postalCode:this.address.postalCode,
            state:this.address.state,
            addedcard:true,
            reg:true,
            devices:this.devices
       })

         swal.fire({
  title: "It's Done!",
  //showDenyButton: true,
  //showCancelButton: true,
  confirmButtonText: `OK`,
  //denyButtonText: `Don't save`,
}).then((result) => {
  /* Read more about isConfirmed, isDenied below */
  if (result.isConfirmed) {
   // swal.fire('Saved!', '', 'success')
    this.$router.push('Dashboard').catch(error => {
   console.info(error.message)
})
  } else if (result.isDenied) {
    swal.fire('Changes are not saved', '', 'info')
  }
})
    }
  }
};
</script>
