<template>
  <div>
    
    <h3 class="title mt-5 text-center">How to save energy?</h3>
    <div class="card-body">
      <div class="row">
        <div class="col-sm-6">
           <card class="mb-3">
  <img slot="image" class="card-img-top" src="data:image/svg+xml;charset=UTF-8,%3Csvg%20width%3D%22320%22%20height%3D%22180%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20viewBox%3D%220%200%20320%20180%22%20preserveAspectRatio%3D%22none%22%3E%3Cdefs%3E%3Cstyle%20type%3D%22text%2Fcss%22%3E%23holder_1664f761b63%20text%20%7B%20fill%3Argba(255%2C255%2C255%2C.75)%3Bfont-weight%3Anormal%3Bfont-family%3AHelvetica%2C%20monospace%3Bfont-size%3A16pt%20%7D%20%3C%2Fstyle%3E%3C%2Fdefs%3E%3Cg%20id%3D%22holder_1664f761b63%22%3E%3Crect%20width%3D%22320%22%20height%3D%22180%22%20fill%3D%22%23777%22%3E%3C%2Frect%3E%3Cg%3E%3Ctext%20x%3D%22110.203125%22%20y%3D%2297.35%22%3EComing%20soon%3C%2Ftext%3E%3C%2Fg%3E%3C%2Fg%3E%3C%2Fsvg%3E" alt="Card image cap">
  <h4 class="card-title">......</h4>
    <p class="card-text">........</p>
    <p class="card-text"><small class="text-muted">Last updated 30 mins ago</small>
  </p>
    <a href="#" class="text-right">View post</a>
       
    
</card>
        </div>
        </div>
      </div>
     
       
     
  
  </div>
</template>
<script>
export default {
  name: 'grid-system'
};
</script>
<style></style>
