<template>
  <ValidationObserver ref="form">
    <form @submit.prevent="validate">
      <div class="row justify-content-center">
        <div class="col-sm-12">
          <h5 class="info-text">Are you living in a nice area?</h5>
        </div>
        <div class="col-sm-7">

          <ValidationProvider
            name="address"
            rules="required|min:5"
            v-slot="{ passed, failed, errors }"
          >
          <base-input
            v-model="address"
            label="Address"
            :error="errors[0]"
            :class="[{ 'has-success': passed }, { 'has-danger': failed }]">
          </base-input>
         </ValidationProvider>
        </div>
        <div class="col-sm-3">
          <ValidationProvider
            name="compl"
            v-slot="{ passed, failed, errors }"
          >
          <base-input
            v-model="compl"
            label="Apt./Ste."
            :error="errors[0]"
            :class="[{ 'has-success': passed }, { 'has-danger': failed }]">
          </base-input>
         </ValidationProvider>
        </div>
        <div class="col-sm-3">
          <ValidationProvider
            name="city"
            rules="required"
            v-slot="{ passed, failed, errors }"
          >
          <base-input
            v-model="city"
            label="City"
            :error="errors[0]"
            :class="[{ 'has-success': passed }, { 'has-danger': failed }]">
          </base-input>
         </ValidationProvider>
        </div>

        <div class="col-sm-2">
          <ValidationProvider
            name="zip"
            rules="required"
            v-slot="{ passed, failed, errors }"
          >
          <base-input
            v-model="postalCode"
            label="Zip Code"
            :error="errors[0]"
            :class="[{ 'has-success': passed }, { 'has-danger': failed }]">
          </base-input>
         </ValidationProvider>
        </div>

 <div class="col-sm-2">
          <label>State</label>

          <ValidationProvider
            name="state"
            rules="required"
            v-slot="{ passed, failed, errors }"
          >
          <base-input>
            <el-select
              v-model="state"
              class="select-primary"
              name="country"
            >
              <el-option
                v-for="state in stateOptions"
                class="select-primary"
                :label="state"
                :value="state"
                :key="state"
              ></el-option>
            </el-select>
          </base-input>
         </ValidationProvider>
        </div>

        <div class="col-sm-3">
          <label>Country</label>

          <ValidationProvider
            name="country"
            rules="required"
            v-slot="{ passed, failed, errors }"
          >
          <base-input>
            <el-select
              v-model="country"
              class="select-primary"
              name="country"
            >
              <el-option
                v-for="country in countryOptions"
                class="select-primary"
                :label="country"
                :value="country"
                :key="country"
              ></el-option>
            </el-select>
          </base-input>
         </ValidationProvider>
        </div>
      </div>
    </form>
  </ValidationObserver>
</template>
<script>
import { Select, Option } from 'element-ui';

import {  extend } from "vee-validate";
import { required, numeric, email, min } from "vee-validate/dist/rules";

extend("email", email);
extend("required", required);
extend("numeric", numeric);
extend("min", min);

export default {
  components: {
    [Select.name]: Select,
    [Option.name]: Option
  },
  
  data() {
    return {
      address: '',
      compl: '',
      city: '',
      postalCode: '',
      state: '',
      country: '',
      countryOptions: [
        'USA',
        'Brazil',
        
      ],
      stateOptions: [
        'FL',
        
      ]
    };
  },
  created(){
this.postalCode = this.$user.postalCode
  },
  methods: {
    getError(fieldName) {
      return this.errors.first(fieldName);
    },
    validate() {
      return this.$refs.form.validate().then(res => {
        //console.log("res)
        if (!res) {
          return;
        }
        this.$emit("on-validated", res);
        if (res) {

          
          let addressform = {
            address:this.address,
            compl:this.compl,
            city:this.city,
            country:this.country,
            postalCode:this.postalCode,
            state:this.state
          }
          this.$emit("on-validated2", addressform);
        }
        
        return res;
      });
    }
  }
};
</script>
<style></style>
